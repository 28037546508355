import React from "react"
import Layout from "../../components/layout"
import MemberPage from "../../components/members/members"

const MembersPage = () => {
  return (
    <Layout>  
     <MemberPage/>
    </Layout>
  )
}

export default MembersPage
